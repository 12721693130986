

.navbtn{
    padding:8px 20px;
    border-radius: 4px;
    outline:none;
    border:none;
    cursor:pointer;
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
}

.navbtn:hover{
    padding:8px 20px;
    transition: all 0.3s ease-out;
    transition: 250ms;
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.navbtn--primary{background-image: linear-gradient(to right, #232526 0%, #414345  51%, #232526  100%)}

.navbtn--medium{
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 18px;
    color: #fff;
}