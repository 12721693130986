.NavbarItems {
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}
.NavbarItemsFixed{
    position: fixed;
    width: 100%;
    z-index: 999;
    transition: 2s;
}
.space{
    margin-top:60px;
}
.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links {
    color: black;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: rgba(223, 209, 148, 0.5);
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.signupLink {
    text-decoration: none;
}

.not-active {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    opacity: 0.5;
  }

  @media screen and (max-width: 1135px) {
    .NavbarItems {
        font-size: 1rem;
    }
}
@media screen and (max-width: 960px) {
    .NavbarItems {
        background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: max-content;
        position: absolute;
        top: 60px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        padding-top: 1rem;
    }

    .nav-menu.active {
        background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 1rem 1rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #4158D0;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 10px);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 50%);
        font-size: 1.5rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 1.5rem;
    }

    .nav-links-mobile {
        background-image: linear-gradient(to right, #232526 0%, #414345 51%, #232526 100%)
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1rem;
        margin: 1rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        color: #fff;
        font-size: 1.2rem;
        transition: all 0.5s ease;
        background-size: 200% auto;
        box-shadow: 0 0 20px #eee;
    }

    .nav-links-mobile:hover {
        background-position: right center;
        color: #fff;
        text-decoration: none;
    }

    .btn-signup,.signupLink {
        display: none !important;
    }
}