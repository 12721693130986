.row {
  display: -ms-flexbox; /* IE 10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE 10 */
  flex-wrap: wrap;
  padding: 0 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}


.column {
  -ms-flex: 25%; /* IE 10 */
  flex: 25%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
}

.imgView{
  width:100%;
}

.img-container{
  position: relative;
}
.imgOverlayer{
  padding: 2px 5px;
  background-color: black;
  width: 100%;
}
.imgbottom{
  color: white;
}
.imgbottom .fa-heart:hover{
  cursor: pointer;
}
.imgheart{
  color:red;
}
.username{
  float:right;
}
.username:hover{
  cursor: pointer;
}
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
  height:420px;
  width: 420px;
}
.showImage{
  display:block;
}
.img-vert{
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.img-hor{
  width: 372px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.img-div{
  position: relative;
  height: 325px;
  margin: 20px 20px 10px 20px;
  border: 4px black solid;
}
.img-details{
  color: black;
}
.img-details p::first-letter{
  text-transform: capitalize;
}

.img-details-container{
  margin: 0px 20px;
}
.img-details-des{
  text-overflow: ellipsis;
  width: calc(100% - 63px);
  white-space: nowrap;
  overflow: hidden;
}
.img-details-author:hover{
  cursor: pointer;
}
.dbtn{
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.closebtn{
  position: absolute;
  top: -35px;
  right: -10px;
  padding: 10px;
  color: white;
  background-color: transparent;
  font-size: 20px;
  outline:none;
  border:none;
}
.closebtn:hover{
  transform:scaleX(1.05);
  cursor: pointer;
}
.search-container {
  overflow: hidden;
  text-align:center
}
.searchbtn {
  padding: 8px 10px;
  margin-top: 8px;
  margin-right: 16px;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.searchbtn:hover {
  background: #ccc;
}
.search-container input[type=text] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: 1px solid black;
  border-radius: 2px;
}
.end{
  text-align: center;
  margin: 20px auto;
  font-size: 20px;
}

@media screen and (max-width: 640px) {
  .popup_inner {
    left: 10%;
    right: 10%;
    height: calc(50%);
    width: calc(80%);
}
.img-div {
  height: 70%;
}
.img-hor {
  width: 100%;
  height: 100%;
}
}

@media screen and (max-width: 300px) {
  .img-vert {
    width: 100%;
  }
}
